import {
  Button,
  Checkbox,
  FormControlLabel,
  StyledEngineProvider,
  Tab,
  Tabs,
  Toolbar,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import React, { Fragment, useMemo, useState } from 'react';

import { DECLINE_LOCATION_SUCCESS, DELETE_LOCATION_SUCCESS, deleteLocation, rejectLocation } from './location.actions';
import { HARDWARE_TYPES, LIFECYCLE_STATUS, LOCATION_TYPES } from '../../types/location.types';
import { LocationDeclineDialogComponent } from './submission/locationDeclineDialog.component';
import { LocationDeleteDialogComponent } from './submission/locationDeleteDialog.component';
import { errorTheme } from '../../styles/themes';
import { handleToastMessage } from '../layout/layout.actions';
import { locationValidator, visibilityBasedValidationFunction } from './editLocation.validation';
import { withRouter } from 'react-router-dom';
import Colors from '../../styles/colors';

export const TABS = {
  LOCATION_DETAILS: 'LOCATION DETAILS',
  REVIEWS: 'REVIEWS',
  OVERVIEW: 'OVERVIEW',
  DEBUG: 'DEBUG',
};

function LocationToolbar({
  selectedTab,
  selectedLocation,
  classes,
  changeTabs,
  history,
  handleApprove,
  handleSave,
  hasDirtyForm,
  disabled,
  isCreate,
  isAdminMode,
  onAdminModeChange,
}) {
  const dispatch = useDispatch();

  const [isDeclineDialogOpen, setIsDeclineDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const isLocationPendingApproval = selectedLocation.lifecycleStatus == LIFECYCLE_STATUS.WAITING_TO_ACTIVATE && selectedLocation.type === LOCATION_TYPES.LOCATION;
  let validator = useMemo(() => locationValidator(selectedLocation), [selectedLocation]);

  const missingRequiredValues = location => {
    return !validator.isLocationValid(location, visibilityBasedValidationFunction);
  };

  const validateAndDelete = selectedLocation => {
    if (!selectedLocation.ttLockConnectionString ){
      setIsDeleteDialogOpen(true);
    } else {
      dispatch(handleToastMessage('This location has the TTLock Connection String set. Must be removed before it can be deleted.'));
    }
  };

  const handleDelete = async deleteReason => {
    const deleteResponse = await dispatch(deleteLocation(selectedLocation, deleteReason));
    if (deleteResponse.type === DELETE_LOCATION_SUCCESS) {
      dispatch(handleToastMessage('Location deleted.'));
      // Only go back if delete was successful
      history.goBack();
    } else {
      dispatch(handleToastMessage('Failed to delete location', true));
    }
  };

  const handleDecline = async declineReason => {
    const response = await dispatch(rejectLocation(selectedLocation, declineReason));

    if (response.type === DECLINE_LOCATION_SUCCESS) {
      dispatch(handleToastMessage('Location has been declined.'));
      history.goBack();
    } else {
      dispatch(handleToastMessage('Failed to decline location', true));
    }
  };
  
  const renderedTabs = useMemo(() => {
    const basicTabs = [
      <Tab className={classes.tab} key={TABS.LOCATION_DETAILS} value={TABS.LOCATION_DETAILS} label={TABS.LOCATION_DETAILS} />,
      <Tab className={classes.tab} key={TABS.REVIEWS} value={TABS.REVIEWS} label={TABS.REVIEWS} />,
    ];

    const addOverviewTab = () => {
      basicTabs.push(<Tab className={classes.tab} key={TABS.OVERVIEW} value={TABS.OVERVIEW} label={TABS.OVERVIEW} />);
    };

    const addDebugTab = () => {
      basicTabs.push(<Tab className={classes.tab} key={TABS.DEBUG} value={TABS.DEBUG} label={TABS.DEBUG} />);
    };

    if (!selectedLocation.podHardwareType) return basicTabs;

    if (
      selectedLocation.podHardwareType === HARDWARE_TYPES.PCD_PAIRED_WITH_TTLOCK ||
      selectedLocation.podHardwareType.startsWith(HARDWARE_TYPES.SMART_POD)
    ) {
      addOverviewTab();
      addDebugTab();
    }
    if (selectedLocation.podHardwareType === HARDWARE_TYPES.VAROOM_TTLOCK || selectedLocation.podHardwareType === HARDWARE_TYPES.TTLOCK_POD) {
      addOverviewTab();
    }
    return basicTabs;
  }, [selectedLocation]);

  return (
    <Toolbar className={classes.toolbar}>
      {!isCreate && (
        <Tabs TabIndicatorProps={{style: {backgroundColor: 'transparent'}}} className={classes.tabs} value={selectedTab} onChange={changeTabs}>
          {renderedTabs}
        </Tabs>
      )}
      <div className={classes.toolbarButtonWrapper}>
        {!isCreate && !isLocationPendingApproval && (
          <>
            {selectedLocation.type !== LOCATION_TYPES.LOCATION && (
              <FormControlLabel
                control={<Checkbox checked={isAdminMode} onClick={() => onAdminModeChange(!isAdminMode)} color="default" />}
                label="Enable Admin Mode"
              />
            )}
            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={errorTheme}>
                <Button 
                  variant="outlined" 
                  color="primary" 
                  className={classes.button} 
                  onClick={() => validateAndDelete(selectedLocation)} 
                  disabled={hasDirtyForm}
                >
                  Delete
                </Button>
              </ThemeProvider>
            </StyledEngineProvider>
          </>
        )}

        {selectedLocation.isUserSubmitted && isLocationPendingApproval && (
          <Fragment>
            <Button variant="outlined" className={classes.button} onClick={() => setIsDeclineDialogOpen(true)}>
              Decline
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={handleApprove}
              disabled={hasDirtyForm}
            >
              Approve
            </Button>
          </Fragment>
        )}

        {!disabled && (
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleSave}
            disabled={!hasDirtyForm || missingRequiredValues(selectedLocation)}
          >
            {isCreate ? 'Create' : 'Save'}
          </Button>
        )}
      </div>
      <LocationDeclineDialogComponent isOpen={isDeclineDialogOpen} onSubmit={rejectReason => handleDecline(rejectReason)} onClose={() => setIsDeclineDialogOpen(false)} />
      <LocationDeleteDialogComponent isOpen={isDeleteDialogOpen} onSubmit={deleteReason => handleDelete(deleteReason)} onClose={() => setIsDeleteDialogOpen(false)}/>
    </Toolbar>
  );
}

const SIDEBAR_WIDTH = 74;

const styles = theme => ({
  tabs: {
    backgroundColor: Colors.secondary.main,
  },
  tab: {
    color: Colors.white,
    fontWeight: 'bold',
  },
  toolbar: {
    backgroundColor: Colors.secondary.main,
    padding: 0,
    position: 'fixed',
    left: SIDEBAR_WIDTH,
    right: 0,
    zIndex: 100,
  },
  toolbarButtonWrapper: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    margin: theme.spacing(1),
  },
});

LocationToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  selectedTab: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isCreate: PropTypes.bool,
  hasDirtyForm: PropTypes.bool.isRequired,
  isAdminMode: PropTypes.bool.isRequired,

  changeTabs: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onAdminModeChange: PropTypes.func.isRequired,
  // Injected by React Router
  history: PropTypes.object.isRequired,
  handleApprove: PropTypes.func,
};

const prepareForExport = compose(withStyles(styles, { withTheme: true }), withRouter);

export default prepareForExport(LocationToolbar);
