import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import withStyles from '@mui/styles/withStyles';

import { AdminEnabledFields } from './editLocation.settings';
import { DEFAULT_SMARTPOD_PIN_CODES, DEFAULT_TTLOCK_PIN_CODES, LIFECYCLE_STATUS } from '../../../types/location.types';
import { DialogContainerComponent } from '../../layout/dialogContainer.component';
import { HARDWARE_FIELDS, shouldShowHardwareField as shouldShowHardwareFieldInternal } from './hardwareFields';
import { HARDWARE_TYPES, LOCATION_TYPES, PCD_HARDWARE_TYPES } from '../../../types/location.types';
import { InputGroup } from './inputGroup.component';
import { REPLACE_LOCATION_LOCK_FAILURE, replaceLocationLock } from '../location.actions';
import { convertMacId } from '../../../utilities/dataUtils';
import { handleToastMessage } from '../../layout/layout.actions';
import { isInitializedTTLock, isTTLockType } from '../../../utilities/locationUtils';
import { locationValidator } from '../editLocation.validation';
import { useDispatch } from 'react-redux';
import MultipleCodesInput from './multipleCodesInput.component';
import copyToClipboard from '../../../utilities/copyToClipboard';

function HardwareSection({ onChange, selectedLocation, isHubspotPreviewMode, onSelectedLocationUpdate, isAdminMode, classes }) {
  const iotId = useMemo(() => convertMacId(selectedLocation.podHardwareIdentifier), [selectedLocation.podHardwareIdentifier]);
  const validator = useMemo(() => locationValidator(selectedLocation), [selectedLocation]);
  const dispatch = useDispatch();
  const [showReplaceLockDialog, setShowReplaceLockDialog] = useState(false);
  const [replacementLockSerial, setReplacementLockSerial] = useState();
  const particleProductId = process.env.REACT_APP_PARTICLE_PRODUCT_ID;
  const particleConsoleUrl = `https://console.particle.io/${particleProductId}/devices?deviceName=${selectedLocation[HARDWARE_FIELDS.POD_MODEM_SERIAL]}`;
  const shouldShowHardwareField = field => {
    return shouldShowHardwareFieldInternal(field, selectedLocation);
  };

  useEffect(() => {
    const isNewLocation = !selectedLocation.id;
    if (isNewLocation) {
      if (!shouldShowHardwareField(HARDWARE_FIELDS.PIN_CODES)) {
        // If podHardwareType changes and pincodes are hidden, clear them out.
        removeAllPinCodes();
      } else if (selectedLocation.pinCodes.length < 1) {
        // If podHardwareType changes and pincodes were previously empty / hidden, set pincodes to default.
        setDefaultPinCodes();
      }
    }
  }, [selectedLocation.podHardwareType]);

  const removeAllPinCodes = () => {
    return onChange({
      target: {
        name: 'pinCodes',
        value: [],
      },
    });
  };

  const setDefaultPinCodes = () => {
    return onChange({
      target: {
        name: 'pinCodes',
        value: isTTLockType(selectedLocation) ? DEFAULT_TTLOCK_PIN_CODES : DEFAULT_SMARTPOD_PIN_CODES,
      },
    });
  };

  const replaceLock = async () => {
    const {response, type, messages} = await dispatch(replaceLocationLock(selectedLocation.id, replacementLockSerial));
    if (type === REPLACE_LOCATION_LOCK_FAILURE) {
      dispatch(handleToastMessage(`Error while replacing lock: ${messages}`, true));
    } else {
      if(response.metadata.warnings.length) {
        dispatch(handleToastMessage(`Location updated with warnings: ${response.metadata.warnings}`, true));
      } else {
        dispatch(handleToastMessage('Location updated with success.'));
      }
      onSelectedLocationUpdate(response.location);
    }
  };

  const renderHardwareType = () => {
    const hardwareTypeLabels = {
      [HARDWARE_TYPES.SMART_POD]: 'SmartPod',
      [HARDWARE_TYPES.SMART_POD_NO_FAN_CONTROLS]: 'SmartPod No Fan Controls',
      [HARDWARE_TYPES.SMART_POD_NO_CONTROLS]: 'SmartPod No Controls',
      [HARDWARE_TYPES.NO_TECH_POD]: 'No-Tech Pod',
      [HARDWARE_TYPES.SMART_LOCK]: 'SmartLock',
      [HARDWARE_TYPES.PCD_PAIRED_WITH_TTLOCK]: 'Smart Pod with TTLock',
      [HARDWARE_TYPES.TTLOCK_POD]: 'TTLock Pod',
      [HARDWARE_TYPES.VAROOM_TTLOCK]: 'VaRoom TTLock',
      [HARDWARE_TYPES.ZTU_POD]: 'ZTU Pod',
    };
    const prebuiltHardwareType = [{ type: HARDWARE_TYPES.PCD_PAIRED_WITH_TTLOCK }, { type: HARDWARE_TYPES.VAROOM_TTLOCK }];
    const defaultHardwareType = [
      { type: HARDWARE_TYPES.SMART_POD },
      { type: HARDWARE_TYPES.SMART_POD_NO_FAN_CONTROLS },
      { type: HARDWARE_TYPES.SMART_POD_NO_CONTROLS },
      { type: HARDWARE_TYPES.NO_TECH_POD },
      { type: HARDWARE_TYPES.SMART_LOCK },
      { type: HARDWARE_TYPES.PCD_PAIRED_WITH_TTLOCK },
      { type: HARDWARE_TYPES.VAROOM_TTLOCK },
      { type: HARDWARE_TYPES.ZTU_POD },
      { type: HARDWARE_TYPES.TTLOCK_POD },
    ];

    return (selectedLocation.lifecycleStatus === LIFECYCLE_STATUS.PREBUILT ? prebuiltHardwareType : defaultHardwareType).map(
      ({ type, disabled }) => (
        <MenuItem value={type} disabled={disabled}>
          {hardwareTypeLabels[type]}
        </MenuItem>
      )
    );
  };

  return (
    <Grid container spacing={2}>
      <InputGroup disableAllChildren={isHubspotPreviewMode} disabledFieldNames={!isAdminMode && AdminEnabledFields}>
        <Grid item xs={12}>
          <Typography variant="h6">Hardware Settings</Typography>
        </Grid>
        {selectedLocation.type === LOCATION_TYPES.MAMAVA && (
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              error={selectedLocation.type === LOCATION_TYPES.MAMAVA && !selectedLocation.podHardwareType}
              fullWidth
            >
              <Tooltip title={isInitializedTTLock(selectedLocation) ? 'Lock is initialized. Cannot change hardware type.' : ''}>
                <TextField
                  aria-label="podHardwareType"
                  name="podHardwareType"
                  label="Hardware Type"
                  value={selectedLocation.podHardwareType}
                  onChange={onChange}
                  select
                  input={<OutlinedInput labelWidth={120} />}
                  disabled={isInitializedTTLock(selectedLocation)}
                >
                  {renderHardwareType()}
                </TextField>
              </Tooltip>
              <FormHelperText>
                {selectedLocation.type === LOCATION_TYPES.MAMAVA && !selectedLocation.podHardwareType ? 'Hardware type is required' : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.PCD_HARDWARE_TYPE) && (
          <Grid item xs={12}>
            <FormControl
              variant="outlined"
              error={selectedLocation.type === LOCATION_TYPES.MAMAVA && !selectedLocation.pcdHardwareType}
              fullWidth
            >
              <TextField
                aria-label="pcdHardwareType"
                name="pcdHardwareType"
                label="PCD Hardware Type"
                variant="outlined"
                value={selectedLocation.pcdHardwareType || ''}
                onChange={onChange}
                select
                input={<OutlinedInput labelWidth={120} />}
              >
                <MenuItem value={PCD_HARDWARE_TYPES.PYCOM}>{PCD_HARDWARE_TYPES.PYCOM}</MenuItem>
                <MenuItem value={PCD_HARDWARE_TYPES.PARTICLE}>{PCD_HARDWARE_TYPES.PARTICLE}</MenuItem>
                <MenuItem value={PCD_HARDWARE_TYPES.NOT_APPLICABLE}>{PCD_HARDWARE_TYPES.NOT_APPLICABLE}</MenuItem>
              </TextField>
            </FormControl>
          </Grid>
        )}

        <Grid item xs={6}>
          <TextField
            fullWidth
            inputProps={{ maxLength: 120 }}
            label="Product Version"
            name="productVersion"
            value={selectedLocation.podType || 'Unknown'}
            variant="outlined"
            disabled
          />
        </Grid>

        {shouldShowHardwareField(HARDWARE_FIELDS.POD_DEVICE_SERIAL) && (
          <Grid item xs={6}>
            <TextField
              name={HARDWARE_FIELDS.POD_DEVICE_SERIAL}
              label={selectedLocation.podHardwareType === HARDWARE_TYPES.VAROOM_TTLOCK ? 'VaRoom Serial #' : 'Pod Serial #'}
              inputProps={{ maxLength: 120 }}
              fullWidth
              value={selectedLocation[HARDWARE_FIELDS.POD_DEVICE_SERIAL] || ''}
              onChange={onChange}
              variant="outlined"
              helperText={validator.isFieldValid(HARDWARE_FIELDS.POD_DEVICE_SERIAL) ? '' : 'Serial # is required'}
              error={!validator.isFieldValid(HARDWARE_FIELDS.POD_DEVICE_SERIAL)}
            />
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.POD_SOFTWARE_VERSION) && (
          <Grid item xs={6}>
            <TextField
              name="podSoftwareVersion"
              label="Pod Software Version"
              fullWidth
              value={selectedLocation.podSoftwareVersion || ''}
              disabled
              variant="outlined"
            />
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.POD_HARDWARE_IDENTIFIER) && (
          <Grid item xs={6}>
            <TextField
              name={HARDWARE_FIELDS.POD_HARDWARE_IDENTIFIER}
              label="Pod Hardware Mac ID"
              inputProps={{ maxLength: 120 }}
              value={selectedLocation.podHardwareIdentifier || ''}
              onChange={onChange}
              fullWidth
              variant="outlined"
              helperText={validator.isFieldValid(HARDWARE_FIELDS.POD_HARDWARE_IDENTIFIER) ? '' : 'Pod Hardware Mac ID is required'}
              error={!validator.isFieldValid(HARDWARE_FIELDS.POD_HARDWARE_IDENTIFIER)}
            />
            {selectedLocation.pcdHardwareType === PCD_HARDWARE_TYPES.PARTICLE ? (
              <Link target="_blank" href={particleConsoleUrl}>
                Navigate to Particle Console
              </Link>
            ) : (
              <Tooltip title="Click to copy to clipboard">
                <Typography variant="body1" onClick={() => copyToClipboard(iotId)}>
                  {`IOT Id: ${iotId}`}
                </Typography>
              </Tooltip>
            )}
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.POD_MODEM_SERIAL) && (
          <Grid item xs={6}>
            <TextField
              name={HARDWARE_FIELDS.POD_MODEM_SERIAL}
              label="PCD Serial #"
              inputProps={{ maxLength: 120 }}
              value={selectedLocation[HARDWARE_FIELDS.POD_MODEM_SERIAL] || ''}
              onChange={onChange}
              fullWidth
              variant="outlined"
              helperText={validator.isFieldValid(HARDWARE_FIELDS.POD_MODEM_SERIAL) ? '' : 'PCD Serial # is required'}
              error={!validator.isFieldValid(HARDWARE_FIELDS.POD_MODEM_SERIAL)}
            />
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.PCD_SIM) && (
          <Grid item xs={12}>
            <TextField
              name={HARDWARE_FIELDS.PCD_SIM}
              label="PCD Sim #"
              inputProps={{ maxLength: 120 }}
              fullWidth
              value={selectedLocation[HARDWARE_FIELDS.PCD_SIM] || ''}
              onChange={onChange}
              variant="outlined"
              helperText={validator.isFieldValid(HARDWARE_FIELDS.PCD_SIM) ? '' : 'PCD Sim # is required'}
              error={!validator.isFieldValid(HARDWARE_FIELDS.PCD_SIM)}
            />
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.HOLOGRAM_DEVICE_ID) && (
          <Grid item xs={6}>
            <TextField
              name={HARDWARE_FIELDS.HOLOGRAM_DEVICE_ID}
              label="Hologram Device ID"
              inputProps={{ maxLength: 120 }}
              value={selectedLocation[HARDWARE_FIELDS.HOLOGRAM_DEVICE_ID] || ''}
              onChange={onChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.PCD_SHARED_SECRET) && (
          <Grid item xs={6}>
            <TextField
              name={HARDWARE_FIELDS.PCD_SHARED_SECRET}
              label="Hologram Device Key"
              inputProps={{ maxLength: 120 }}
              value={selectedLocation[HARDWARE_FIELDS.PCD_SHARED_SECRET] || ''}
              onChange={onChange}
              fullWidth
              variant="outlined"
            />
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.DISABLE_DATA_COLLECTION) && (
          <Grid item xs={6}>
            <FormControlLabel
              label="Disable Data Collection"
              control={
                <Switch
                  aria-label="disable data collection"
                  name="disableDataCollection"
                  checked={selectedLocation.disableDataCollection}
                  onChange={e => {
                    onChange({ target: { value: e.target.checked, name: 'disableDataCollection' } });
                  }}
                  value="disableDataCollection"
                  color="primary"
                />
              }
            />
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.REPLACE_LOCK) && (
          <Grid item xs={6}>
            <DialogContainerComponent isOpen={showReplaceLockDialog} isSubmitDisabled={!replacementLockSerial} onClose={()=> setShowReplaceLockDialog(false)} onSubmit={() => replaceLock()} title="Enter Linnea Lock Serial Number">
              <TextField
                style={{marginTop: 5, marginBottom: 5}}
                label="Linnea Lock Serial Number"
                inputProps={{ maxLength: 120 }}
                fullWidth
                onChange={(e) => setReplacementLockSerial(e.target.value)}
                variant="outlined"
                helperText={validator.isFieldValid(HARDWARE_FIELDS.PCD_SIM) ? '' : 'PCD Sim # is required'}
                error={!validator.isFieldValid(HARDWARE_FIELDS.PCD_SIM)}
              />
            </DialogContainerComponent>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => setShowReplaceLockDialog(true)}
            >
              Replace Lock
            </Button>
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.TTLOCK_SERIAL) && (
          <Grid item xs={6}>
            <TextField
              name={HARDWARE_FIELDS.TTLOCK_SERIAL}
              label="TTLock Serial"
              inputProps={{ maxLength: 120 }}
              fullWidth
              value={selectedLocation[HARDWARE_FIELDS.TTLOCK_SERIAL] || ''}
              onChange={onChange}
              variant="outlined"
              helperText={validator.isFieldValid(HARDWARE_FIELDS.TTLOCK_SERIAL) ? '' : 'TTLock Serial is required'}
              error={!validator.isFieldValid(HARDWARE_FIELDS.TTLOCK_SERIAL)}
            />
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.TTLOCK_MODEL) && (
          <Grid item xs={6}>
            <TextField
              name={HARDWARE_FIELDS.TTLOCK_MODEL}
              label="TTLock Model"
              inputProps={{ maxLength: 120 }}
              fullWidth
              value={selectedLocation[HARDWARE_FIELDS.TTLOCK_MODEL] || ''}
              onChange={onChange}
              variant="outlined"
              disabled
            />
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.TTLOCK_NAME) && (
          <Grid item xs={6}>
            <TextField
              name={HARDWARE_FIELDS.TTLOCK_NAME}
              label="TTLock Lock Name"
              inputProps={{ maxLength: 120 }}
              fullWidth
              value={selectedLocation[HARDWARE_FIELDS.TTLOCK_NAME] || ''}
              onChange={onChange}
              variant="outlined"
            />
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.TTLOCK_HARDWARE_IDENTIFIER) && (
          <Grid item xs={6}>
            <TextField
              name={HARDWARE_FIELDS.TTLOCK_HARDWARE_IDENTIFIER}
              label="TTLock Lock Mac ID"
              inputProps={{ maxLength: 120 }}
              fullWidth
              value={selectedLocation[HARDWARE_FIELDS.TTLOCK_HARDWARE_IDENTIFIER] || ''}
              onChange={onChange}
              variant="outlined"
              helperText={validator.isFieldValid(HARDWARE_FIELDS.TTLOCK_HARDWARE_IDENTIFIER) ? '' : 'Lock Mac ID is required'}
              error={!validator.isFieldValid(HARDWARE_FIELDS.TTLOCK_HARDWARE_IDENTIFIER)}
            />
          </Grid>
        )}

        {shouldShowHardwareField(HARDWARE_FIELDS.TTLOCK_CONNECTION_STRING) && (
          <Grid item xs={12}>
            <TextField
              name={HARDWARE_FIELDS.TTLOCK_CONNECTION_STRING}
              label="TTLock Connection String"
              multiline
              onChange={onChange}
              rows="10"
              fullWidth
              value={selectedLocation[HARDWARE_FIELDS.TTLOCK_CONNECTION_STRING] || ''}
              variant="outlined"
            />
          </Grid>
        )}

        {selectedLocation.type === LOCATION_TYPES.MAMAVA && shouldShowHardwareField(HARDWARE_FIELDS.PIN_CODES) && (
          <>
            <MultipleCodesInput
              isDisabled={isInitializedTTLock(selectedLocation)}
              disabledErrorMessage="Lock initialized. Use Mamava for Business to update Pin Codes."
              isHubspotPreviewMode={isHubspotPreviewMode}
              onCodeChange={value => onChange({ target: { name: 'pinCodes', value } })}
              inputLabel="Add Pin codes"
              codes={selectedLocation.pinCodes || []}
              errorMessage="Unable to add pin code. Please remove an existing pin code and try again."
              codeInsertionValidation={input => input.length < 8}
            />
            {selectedLocation.pinCodes?.length < 1 && <Typography className={classes.pincodeWarning} variant="h5" color="error">No Pincodes Set</Typography>}
          </>
        )}
      </InputGroup>
    </Grid>
  );
}

const styles = theme => ({
  pincodeWarning: {
    marginLeft: theme.spacing(2),
  },
});

HardwareSection.propTypes = {
  classes: PropTypes.object.isRequired,
  selectedLocation: PropTypes.object.isRequired,
  isHubspotPreviewMode: PropTypes.bool,
  isAdminMode: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

const prepareForExport = compose(withStyles(styles, { withTheme: true }));

export default prepareForExport(HardwareSection);
